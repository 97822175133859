<template lang="pug">

	.mixer

		section.mixer__section.mixer__section--main

			img.mixer__img.mixer__img--main(src="~@/assets/img/mixer/color_mixer_main.jpg")

			.mixer__container(class="container u-h100")

				.mixer__content

					h1.mixer__title(class="primary-title") Color Mixer

					p.mixer__sub-title(class="sub-title") With the Color Mixer you can design the perfect blend for your next project. Mix any combination of our 19 different colored granules to come up with the perfect color combination for your Project.


		section.mixer__section.mixer__section--mixer

			.mixer__container(class="container u-h100")

				BaseColorMixerComponent

		section.mixer__section.mixer__section--article(class="u-df-between")

			.mixer__container.mixer__container--article(class="container u-h100 u-df-center")
				.mixer__article-title(class="article-title") {{ articleTitle }}
				.mixer__article-body {{ articleBody }}


		section.mixer__section.mixer__section--contact(class="u-df-center")

			img.mixer__img.mixer__img--contact(src="~@/assets/img/contact_section_back.jpg")
		
			.mixer__container.mixer__container--contact(class="container u-df-between")
				BaseContactFormComponent(:caption="caption" :article="article")

	
</template>

<script>
export default {
	name: 'ColorMixerView',

	data() {
		return {
			articleTitle: 'Color Disclaimer',
			articleBody: 'Do not rely on screen representation for exact color accuracy. We cannot guarantee an exact match to the color appearing on screen due to varying screen settings.  This is a great tool to get an idea of what the different colors will look like mixed together.  We are pleased to provide custom samples based upon which blend you chose prior to installation.',
			caption: 'If you are interested in hearing more about our services, please',
			article: ''
		}
	}
}
</script>

<style scoped lang="sass">
@import '~@/assets/styles/abstracts/_variables.sass'


.mixer
	
	
	&__section


		&--main
			min-height: 65vh
			position: relative


			@media screen and (max-width: $phone)
				min-height: 51rem


		&--mixer
			margin: 10rem 0

			display: flex
			align-items: center


			@media screen and (max-width: $phone)
				margin: 2rem 0 10rem


		&--article
			height: 28.8rem
			padding: 8.2rem 0

			background: $color_white_grey


			@media screen and (max-width: $phone)
				height: unset
			

		&--contact
			position: relative
			margin: 20rem 0 15rem


			@media screen and (max-width: $big_desktop)
				margin-top: 10rem


			@media screen and (max-width: $phone)
				margin-bottom: 0
	
	
	&__img


		&--main
			object-fit: contain
			object-position: top

			position: absolute
			
			top: 0
			right: 0
			
			height: 65rem

			max-height: 150vh
			max-width: 61%

			z-index: -1


			@media screen and (max-width: $phone)
				max-height: 31rem
				max-width: 99.5%


		&--contact
			object-fit: contain
			object-position: right

			position: absolute
			
			top: -4rem
			right: 0

			height: 120%
			max-width: 50%

			z-index: -1


			@media screen and (max-width: $phone)
				top: 30rem
				max-width: 80%


	&__container


		&--article
			align-items: stretch


			@media screen and (max-width: $phone)
				flex-direction: column


		&--contact


			@media screen and (max-width: $phone)
				flex-direction: column


	&__content
		position: absolute

		top: 27vh


		@media screen and (max-width: $phone)
			width: 89%
			top: 22rem

			text-align: center


	&__title
		width: 57.9rem
		margin-bottom: 4rem


		@media screen and (max-width: $phone)
			width: 100%


	&__sub-title
		width: 51.3rem
		margin-bottom: 8rem


		@media screen and (max-width: $phone)
			width: 100%
			margin-bottom: 5rem


	&__article


		&-title
			flex-basis: 30%


			@media screen and (max-width: $phone)
				margin-bottom: 4rem


		&-body
			flex-basis: 70%


</style>